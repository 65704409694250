@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.imgbg {
      background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

@layer components {
  .ic-btn-primray {
    @apply inline-flex justify-center items-center border-2 border-transparent rounded-full shadow-sm bg-ic-primary
    hover:bg-white hover:text-ic-secondary hover:border-ic-primary
    focus:outline-none text-white font-bold;
  }

  .ic-input-form {
    @apply first-line:block w-full mt-1 border-gray-300 rounded-md shadow-sm
    focus:ring-ic-primary focus:border-ic-primary sm:text-sm
    read-only:bg-slate-50 read-only:text-slate-500
    read-only:border-slate-200 read-only:shadow-none;
  }

  .ic-label-form {
    @apply first-line:block text-sm font-medium text-gray-700;
  }
}
